export default () => {
  const modalTrigger = document.querySelectorAll(".modal-trigger");

  let delay = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  // a fix for ie11
  if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = Array.prototype.forEach;
  }

  modalTrigger.forEach(function(trigger) {
    trigger.addEventListener("click", function(event) {
      //// Should Always be First
      event.preventDefault();
      // remove "#" from #modal
      const target = this.getAttribute("href").substr(1);

      const modalWindow = document.querySelector(`#${target}`);
      const docBody = document.querySelector(".js-body");
      const searchInput = document.querySelector("input.search-form-input");

      if (!modalWindow) return;
      if (modalWindow.classList.contains("open")) {
        // modalWindow.classList.add("fade-out");
        delay(0).then(() => {
          //500
          trigger.classList.toggle("open");
          docBody.classList.toggle("modal-open");
          modalWindow.classList.toggle("open");

          // modalWindow.classList.remove("fade-out");
        });
      } else {
        docBody.classList.toggle("modal-open");
        modalWindow.classList.toggle("open");
        trigger.classList.toggle("open");
        delay(500).then(() => {
          searchInput.focus();
          // modalWindow.classList.remove("fade-out");
        });
      }

      //// Below here, instead of listening for an event on every overlay right off out the gate, instead we'll apply a reverse listener, what we are doing here is listening for any click on the Dom that is NOt inside the modal content and not the button of the modal. You could wrap this in a delay promoise if you wanted.

      const modalInner = modalWindow.querySelector(".modal-container");

      ////Get the Close Button
      const modalClose = modalWindow.querySelector(".modal-close");

      ////Define Click outside so we can remove it later.
      let closeModal = (e) => {
        if (modalClose.contains(e.target)) {
          // modalWindow.classList.add("fade-out");
          delay(500).then(() => {
            trigger.classList.toggle("open");
            modalWindow.classList.toggle("open");
            docBody.classList.toggle("modal-open");
            // modalWindow.classList.remove("fade-out");
          });

          //// Removes the now need event Listener
          document.removeEventListener("click", closeModal);
          modalClose.removeEventListener("click", closeModal);
        }
      };

      ////Add the Close Event Listeners
      // modalClose.addEventListener("click", closeModal);
      // document.addEventListener("click", closeModal);
    });
  });
};
