import Swiper, { Navigation } from "swiper";

// configure Swiper to use modules
Swiper.use([Navigation]);

export default () => {
  const elements = document.querySelectorAll("*[accordion-carousel]");
  if (!elements && elements.lenght > 0) return;

  Array.prototype.forEach.call(elements, (element) => {
    var mySwiper = new Swiper(element, {
      pagination: {
        el: ".swiper-pagination",
        type: "fraction",
      },
      navigation: {
        nextEl: ".swiper-button-next-custom",
        prevEl: ".swiper-button-prev-custom",
      },

      slidesPerView: 1.5,
      spaceBetween: 10,
    });
  });
};
