import Vue from 'vue';
import events from "./../apps/contentGrid.vue";

export default () => {

    // get last item oin array https://stackoverflow.com/questions/9050345/selecting-last-element-in-javascript-array?rq=1

    let newApp = document.getElementsByClassName("caseStudyFilterGrid");

    if (!newApp) return

    [].slice.call(newApp).forEach(x=>
    new Vue({
        el: x,
        render: h => h(events),
    })
    )
}