var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "filter-grid grid side-primary" }, [
    _c("div", { staticClass: "filter-grid--side side" }),
    _vm._v(" "),
    _c("div", { staticClass: "filter-grip__top-heading flex" }, [
      _c("div", { staticClass: "filter-grid__heading flex" }, [
        _c("h2", [_vm._v(_vm._s(_vm.title))]),
        _vm._v(" "),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.tag,
                expression: "tag",
              },
            ],
            staticClass: "filter-grid__select",
            on: {
              change: [
                function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.tag = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
                function ($event) {
                  return _vm.onChange($event)
                },
              ],
            },
          },
          _vm._l(_vm.tags, function (t, index) {
            return _c("option", { key: index }, [_vm._v(_vm._s(t.Name))])
          }),
          0
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "filter-grid__word" }, [_vm._v("Explore")]),
    _vm._v(" "),
    _c("div", { staticClass: "filter-grip__top-main" }, [
      _c("div", { staticClass: "filter-grid__position flex" }, [
        _c(
          "ul",
          { staticClass: "filter-grid__filters filter-grid__filters--b flex" },
          [
            _vm._l(_vm.tags, function (t) {
              return [
                _c(
                  "li",
                  {
                    class: { active: t.active },
                    on: {
                      click: function ($event) {
                        return _vm.tagFilter(t.Name)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n            " + _vm._s(t.Name) + "\n            "
                    ),
                    _c("span", [_vm._v(_vm._s(t.Count))]),
                  ]
                ),
              ]
            }),
          ],
          2
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "side filter-grid__side" }),
    _vm._v(" "),
    _c("div", { staticClass: "filter-grid__main" }, [
      _c(
        "ul",
        { staticClass: "filter-grid__all-cards flex" },
        _vm._l(_vm.items, function (item) {
          return _c("li", { staticClass: "filter-grid__card" }, [
            _c("div", { staticClass: "filter-grid__inner" }, [
              _c("div", { staticClass: "filter-grid__top" }, [
                _c("div", { staticClass: "filter-grid__img" }, [
                  _c("a", { attrs: { href: item.Url } }, [
                    _c("img", {
                      attrs: {
                        source: "",
                        srcset: item.ImageUrl,
                        sizes: "415px, 207px",
                        loading: "lazy",
                        alt: item.Title,
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "filer-grid__content" }, [
                  _c("h5", [_vm._v(_vm._s(item.Title))]),
                  _vm._v(
                    "\n              " +
                      _vm._s(item.Synopsis) +
                      "\n            "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "filter-grid__bottom" }, [
                _c("a", { attrs: { href: item.Url } }, [_vm._v("read more")]),
              ]),
            ]),
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c("div", { staticClass: "filter-grid__loading flex" }, [
        _c("div", { staticClass: "filter-grid__pagination" }, [
          _vm._v("\n        showing\n        "),
          _c("span", [_vm._v(_vm._s(_vm.items.length))]),
          _vm._v(" of\n        "),
          _c("span", [_vm._v(_vm._s(_vm.itemsTotal))]),
        ]),
        _vm._v(" "),
        _c("label", { attrs: { for: "file" } }, [_vm._v("progress:")]),
        _vm._v(" "),
        _c(
          "progress",
          {
            staticClass: "progress",
            attrs: { id: "unique", max: "100" },
            domProps: { value: _vm.progress },
          },
          [_vm._v(_vm._s(_vm.progress) + "%")]
        ),
        _vm._v(" "),
        _vm.items.length < _vm.itemsTotal
          ? _c(
              "button",
              {
                staticClass: "btn btn--load-more",
                on: {
                  click: function ($event) {
                    return _vm.loadMore()
                  },
                },
              },
              [_vm._v("load more")]
            )
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }