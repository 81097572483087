import Player from "@vimeo/player";

export default () => {
  let wrapper = document.querySelectorAll(".vimeo-wrapper__outer");

  if (!wrapper && wrapper.length == 0) return;

  let iframe = document.getElementById("full-width-video");
  if (!iframe) return;
  
  let btn = document.querySelector(".video-sound-toggle");
  let player = new Player(iframe);

  if (!player && player.length == 0) return;

  let showControls = document.querySelector(".reveal-sound-control");

    if (showControls) {
        showControls.addEventListener("click", (e) => {
            e.target.classList.add("hidden");
            btn.classList.add("visible");
        });

        iframe.addEventListener("mouseover", (e) => {
            showControls.style.backgroundColor = "transparent";
        });

        btn.addEventListener("click", (e) => {
            btn.classList.toggle("unmuted");
        });

        player.on("play", (data) => {
            btn.addEventListener("click", (e) => {
                player.getMuted().then(function (muted) {
                    player.setMuted(!muted);
                });
            });
        });
    }
};
