import Swiper, { Navigation, Pagination, Autoplay } from "swiper";

// configure Swiper to use modules
Swiper.use([Navigation, Pagination, Autoplay]);

var ProgressBar = require("progressbar.js");

export default () => {
  var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

  window.addEventListener("load", () => {
    const featureContainers = document.querySelectorAll("*[feature-carousel]");

    if (!featureContainers && featureContainers.length > 0) return;

    featureContainers.forEach((featureContainer) => {
      let autoplayTiming = 5600;

      const featureGallery = featureContainer.querySelector(".feature-gallery");

      if (!featureGallery) return;
      //github.com/kimmobrunfeldt/progressbar.js/issues/255

      let featurePaingation = featureContainer.querySelector(
        ".swiper-pagination-feature"
      );

      let featureNextButton = featureContainer.querySelector(
        ".swiper-button-next-custom"
      );
      let featurePrevButton = featureContainer.querySelector(
        ".swiper-button-prev-custom"
      );

      let featureBarNull = featureContainer.querySelector(
        ".feature-progressbar"
      );
      if (!featureBarNull) return;

      if (!isIE11) {
        let featureBar = new ProgressBar.Circle(jsProgressFeature, {
          strokeWidth: 6,
          easing: "easeInOut",
          duration: 5000,
          color: "#009ade",
          trailColor: "#eee",
          trailWidth: 1,
          svgStyle: null,
          set: 0,
        });

        let featureTrigger = featureContainer.querySelectorAll(
          ".js-swiper-word"
        );

        let galleryFeatureTop = new Swiper(featureGallery, {
          slidesPerView: 1,
          centeredSlides: true,
          loop: true,
          speed: 1000,
          transition: 0,
          autoplay: {
            delay: autoplayTiming,
            disableOnInteraction: false,
          },
          navigation: {
            nextEl: featureNextButton,
            prevEl: featurePrevButton,
          },
          pagination: {
            el: featurePaingation,
            type: "fraction",
          },

          on: {
            slideChangeTransitionStart: function() {
              featureTrigger.forEach(function(x) {
                x.classList.remove("active");
              });
              featureBar.set(0);
            },

            slideChangeTransitionEnd: function() {
              let a = this.realIndex;
              featureTrigger[a].classList.add("active");
              featureBar.animate(1); // Number from 0.0 to 1.0
            },
          },
        });

        featureTrigger.forEach((element, index) => {     

          element.addEventListener("click", (e) => {
            e.preventDefault();
            galleryFeatureTop.slideTo(index + 1);
          });
        });
      } else if (isIE11) {
        let featureTrigger = [].slice.call(
          document.getElementsByClassName("js-swiper-word")
        );

        let galleryFeatureTop = new Swiper(featureGallery, {
          slidesPerView: 1,
          centeredSlides: true,
          loop: true,
          speed: 1000,
          transition: 0,
          autoplay: {
            delay: autoplay,
            disableOnInteraction: false,
          },
          navigation: {
            nextEl: featureNextButton,
            prevEl: featurePrevButton,
          },
          pagination: {
            el: featurePaingation,
            type: "fraction",
          },

          on: {
            slideChangeTransitionStart: function() {
              featureTrigger.forEach(function(x) {
                x.classList.remove("active");
              });
            },

            slideChangeTransitionEnd: function() {
              let a = this.realIndex;
              featureTrigger[a].classList.add("active");
            },
          },
        });

        featureTrigger.forEach((element, index) => {
          console.log(index);
          element.addEventListener("click", (e) => {
            e.preventDefault();
            galleryFeatureTop.slideTo(index + 1);
          });
        });
      }
    }, false);
  }); // end forEach
};
