// import $ from "jquery";
// import "jquery-validation-unobtrusive";
require("es6-promise").polyfill();
import "core-js/features/number/is-nan";
import svg4everybody from "svg4everybody";
import objectFitImages from "object-fit-images";
import picturefill from "picturefill";
import objectFit from "./modules/objectFit";
import accordions from "./modules/accordions";
import modal from "./modules/modal";
import imgGrid from "./modules/imgGrid";
import logoGrid from "./modules/logoGrid";
import swiperAccordions from "./modules/swiperAccordions";
import swiperHomeHero from "./modules/swiperHomeHero";
import swiperFeature from "./modules/swiperFeature";
import cookies from "./modules/cookies";
import videoControl from "./modules/videoControl";
/*import splide from "./modules/splide.min.js";*/

///// vue bits
import vueModuleContentGrid from "./modules/vueModuleContentGrid";
import vueModuleSearch from "./modules/vueModuleSearch";

svg4everybody();
picturefill();
accordions();
modal();
imgGrid();
logoGrid();
swiperHomeHero();
swiperFeature();
swiperAccordions();
cookies();
objectFitImages();
objectFit();
videoControl();
/*splide();*/

vueModuleSearch();
vueModuleContentGrid();
