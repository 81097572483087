import axios from "axios";

let searchService = async (searchTerm, page, pageType, totalResults) => {
  let response = await axios.get(
    `/umbraco/api/SearchApi/search/get?query=${searchTerm}&page=${
      page ? page : 1
    }&pageType=${pageType ? pageType : 0} &totalResults=${
      totalResults ? totalResults : 0
    }`
  );
  if (!response) return;
  return response.data;
};

export default searchService;
