var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "result-block" }, [
    _c(
      "a",
      {
        staticClass: "result-block__image square-image",
        attrs: { href: _vm.content.url },
      },
      [
        _c("picture", [
          _c("source", {
            attrs: { srcset: _vm.content.ImageUrl, media: "(min-width: 0)" },
          }),
          _vm._v(" "),
          _c("img", { staticClass: "square-image__image" }),
        ]),
        _vm._v(" "),
        _vm.content.PageType
          ? _c("span", { staticClass: "grid-3__imgTag" })
          : _vm._e(),
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "grid-3__txt" }, [
      _c(
        "a",
        { staticClass: "grid-3__title", attrs: { href: _vm.content.Url } },
        [_vm._v(_vm._s(_vm.content.Title))]
      ),
      _vm._v(" "),
      _c("p", [_vm._v(_vm._s(_vm.content.Snippet.slice(0, 80)))]),
      _vm._v(" "),
      _c("div", { staticClass: "grid-3__link" }, [
        _c(
          "a",
          { staticClass: "btn btn--link", attrs: { href: _vm.content.Url } },
          [_vm._v(_vm._s(_vm.content.CustomLinkTitle))]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }