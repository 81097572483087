import Swiper, {
  Navigation,
  Pagination,
  Thumbs,
  Controller,
  Autoplay,
} from "swiper";

// configure Swiper to use modules
Swiper.use([Navigation, Pagination, Thumbs, Controller, Autoplay]);
var ProgressBar = require("progressbar.js");

export default () => {
  const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
  window.addEventListener("load", () => {
    const heroContainers = document.querySelectorAll("*[hero-carousel]");
    if (!heroContainers && heroContainers.length > 0) return;

    Array.prototype.forEach.call(
      heroContainers,
      (heroContainer) => {
        let autoplayTiming = 5500;

        let heroMain = heroContainer.querySelector(".gallery-top");
        let heroThumbs = heroContainer.querySelector(".gallery-thumbs");

        let heroPaingation = heroContainer.querySelector(".swiper-pagination");

        let heroNextButton = heroContainer.querySelector(
          ".swiper-button-next-custom"
        );
        let heroPrevButton = heroContainer.querySelector(
          ".swiper-button-prev-custom"
        );
        if (!isIE11) {
          let bar = new ProgressBar.Circle(jsProgress, {
            strokeWidth: 6,
            easing: "easeInOut",
            duration: 5000,
            color: "#009ade",
            trailColor: "#eee",
            trailWidth: 1,
            svgStyle: null,
          });

          let galleryThumbs, galleryTop;

          galleryThumbs = new Swiper(heroThumbs, {
            spaceBetween: 0,
            slidesPerView: 3,
            simulateTouch: true,

            followFinger: false,

            slideToClickedSlide: true,
            loop: true,
            loopedSlides: 5,
            speed: 1500,
          });

          galleryTop = new Swiper(heroMain, {
            slidesPerView: 1,
            speed: 1500,
            loop: true,
            loopedSlides: 5,

            simulateTouch: true,

            followFinger: false,

            autoplay: {
              delay: autoplayTiming,
              disableOnInteraction: true,
            },
            navigation: {
              nextEl: heroNextButton,
              prevEl: heroPrevButton,
            },
            pagination: {
              el: heroPaingation,
              type: "fraction",
            },
            thumbs: {
              swiper: galleryThumbs,
            },
            controller: {
              control: galleryThumbs,
            },

            on: {
              slideChangeTransitionStart: function(i) {
                bar.set(0);
              },

              slideChangeTransitionEnd: function(i) {
                if (!this.autoplay.running) {
                  this.autoplay.start();
                }
                bar.animate(1); // Number from 0.0 to 1.0
              },
            },
          });
        } else if (isIE11) {
          let galleryThumbs, galleryTop;

          galleryThumbs = new Swiper(heroThumbs, {
            spaceBetween: 0,
            slidesPerView: 3,
            simulateTouch: true,
            followFinger: false,
            slideToClickedSlide: true,
            loop: true,
            loopedSlides: 5,
            speed: 1500,
          });

          galleryTop = new Swiper(heroMain, {
            slidesPerView: 1,
            speed: 1500,
            loop: true,
            loopedSlides: 5,
            simulateTouch: true,
            followFinger: false,
            autoplay: {
              delay: autoplay,
              disableOnInteraction: true,
            },
            navigation: {
              nextEl: heroNextButton,
              prevEl: heroPrevButton,
            },
            pagination: {
              el: heroPaingation,
              type: "fraction",
            },
            thumbs: {
              swiper: galleryThumbs,
            },
            controller: {
              control: galleryThumbs,
            },

            on: {
              slideChangeTransitionStart: function(i) {
                autoplay;
              },

              slideChangeTransitionEnd: function(i) {},
            },
          });
        }
      },
      false
    );
  }); // end forEach
};
