var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal-container modal-container__search",
      class: _vm.classObject,
      attrs: { role: "document" },
    },
    [
      _c("div", { staticClass: "modal-results-top" }, [
        _c("div", { staticClass: "modal-container__innerSearch" }, [
          _c("form", { attrs: { action: "/search" } }, [
            _c("div", { staticClass: "form-contents" }, [
              _c("label", { class: _vm.classObject }, [_vm._v("Search")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchTerm,
                    expression: "searchTerm",
                  },
                ],
                staticClass: "search-form-input",
                attrs: {
                  placeholder: "Type here...",
                  type: "search",
                  title: "search",
                  name: "title",
                  required: "required",
                },
                domProps: { value: _vm.searchTerm },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.searchTerm = $event.target.value
                  },
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _vm.results.length !== 0 && _vm.searchTerm !== "" && !_vm.showLoading
            ? _c("div", { staticClass: "paginate-bottom flex" }, [
                _c("p", { staticClass: "paginate-info" }, [
                  _vm._v("\n          Showing\n          "),
                  _c("span", [_vm._v(_vm._s(_vm.results.length))]),
                  _vm._v(
                    "\n          results of " +
                      _vm._s(_vm.totalResults) +
                      "\n        "
                  ),
                ]),
                _vm._v(" "),
                _vm.results.length >= 1
                  ? _c(
                      "div",
                      { staticClass: "paginate-container" },
                      _vm._l(_vm.pages, function (page, i) {
                        return _c(
                          "a",
                          {
                            key: `pager_${page}`,
                            class: { active: i === _vm.activeItem },
                            on: {
                              click: function ($event) {
                                _vm.changePage(page)
                                _vm.selectItem(i)
                              },
                            },
                          },
                          [_vm._v(_vm._s(page))]
                        )
                      }),
                      0
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "modal-results--container" }, [
        _vm.results.length === 0 || (_vm.searchTerm !== "" && !_vm.showLoading)
          ? _c(
              "div",
              { staticClass: "modal-results flex" },
              [
                _vm._l(_vm.results, function (item, index) {
                  return _c("articleCardBlock", {
                    key: `result_${index}`,
                    attrs: { content: item, index: index },
                  })
                }),
                _vm._v(" "),
                _vm.results.length === 0 &&
                _vm.searchTerm !== "" &&
                !_vm.showLoading
                  ? _c("div", { staticClass: "result-block--no-results" }, [
                      _vm._v("No Results Found"),
                    ])
                  : _vm._e(),
              ],
              2
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }