import Vue from "vue";
import VuePaginate from "vue-paginate";
import search from "./../apps/search.vue";
// import searchOverlay from "./../apps/searchOverlay.vue";
Vue.use(VuePaginate);

export default () => {
  let newApp = document.getElementById("js-dynamic-search");

  if (newApp) {
    new Vue({
      el: newApp,
      render: (h) => h(search),
    });
  }

  //   let searchModal = document.querySelector("*[search-button]");

  //   if (searchModal) {
  //     new Vue({
  //       el: searchModal,
  //       render: (h) => h(searchOverlay),
  //     });
  //   }
};
