export default () => {
  (function() {
    // Source: https://github.com/jserz/js_piece/blob/master/DOM/ParentNode/prepend()/prepend().md
    (function(arr) {
      arr.forEach(function(item) {
        if (item.hasOwnProperty("prepend")) {
          return;
        }
        Object.defineProperty(item, "prepend", {
          configurable: true,
          enumerable: true,
          writable: true,
          value: function prepend() {
            var argArr = Array.prototype.slice.call(arguments),
              docFrag = document.createDocumentFragment();

            argArr.forEach(function(argItem) {
              var isNode = argItem instanceof Node;
              docFrag.appendChild(
                isNode ? argItem : document.createTextNode(String(argItem))
              );
            });

            this.insertBefore(docFrag, this.firstChild);
          },
        });
      });
    })([Element.prototype, Document.prototype, DocumentFragment.prototype]);
    // end Prepend polyfill

    Date.prototype.addDays = function(days) {
      var date = new Date(this.valueOf());
      date.setDate(date.getDate() + days);
      return date;
    };

    var setCookie = function setCookie(name) {
      var newDate = new Date();
      document.cookie = ""
        .concat(name, "=true; path=/;domain=.")
        .concat(window.location.host, ";expires=")
        .concat(newDate.addDays(60), ";");
    };

    var getCookie = function getCookie(name) {
      var value = "; " + document.cookie;
      var parts = value.split("; " + name + "=");
      if (parts.length == 2)
        return parts
          .pop()
          .split(";")
          .shift();
      return null;
    };

    function createCookieNotice() {
      var cookieNotice = document.createElement("div");
      var cookieNoticeInner = document.createElement("div");

      var cookieAcceptButton = document.createElement("button");

      var cookieMessageHTML =
        document.getElementById("cookieMessageHTML") !== null &&
        document.getElementById("cookieMessageHTML").value.length > 0
          ? document.getElementById("cookieMessageHTML").value
          : "<p>This site uses cookies. By continuing to use this website, you accept our <a href='/privacy-policy/'>use of cookies.</a></p>";
      var cookieButtonLabel =
        document.getElementById("cookieButtonLabel") !== null &&
        document.getElementById("cookieButtonLabel").value.length > 0
          ? document.getElementById("cookieButtonLabel").value
          : "Accept";

      cookieAcceptButton.innerHTML = cookieButtonLabel;

      cookieAcceptButton.onclick = function() {
        document.body.removeChild(cookieNotice);
        setCookie("privacyPolicyAccepted");
      };

      cookieNotice.classList.add("cookieNotice");
      cookieNoticeInner.classList.add("cookieNoticeInner");
      cookieNoticeInner.innerHTML = cookieMessageHTML;
      cookieNoticeInner.appendChild(cookieAcceptButton);
      cookieAcceptButton.classList.add("primary-cta");
      cookieNotice.appendChild(cookieNoticeInner);

      return cookieNotice;
    }

    if (getCookie("privacyPolicyAccepted") == null) {
      document.body.prepend(createCookieNotice());
    }
  })();
};
