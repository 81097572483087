export default () => {
  const imgGridTrigger = document.querySelectorAll(".js-logoGrid-trigger");

  let delay = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  imgGridTrigger.forEach(function(trigger) {
    trigger.addEventListener("mouseover", function(event) {
      //// Should Always be First
      event.preventDefault();
      // remove "#" from #trigger
      const target = this.dataset.parent;
      // use dynamic target to reference given target
      const imgGridTarget = document.getElementById(target);

      //// Dont nest checked where possible
      if (!imgGridTarget) return;
      imgGridTarget.classList.add("active");
    });

    trigger.addEventListener("mouseleave", function(event) {
      //// Should Always be First
      event.preventDefault();
      // remove "#" from #trigger
      const target = this.dataset.parent;
      // use dynamic target to reference given target
      const imgGridTarget = document.getElementById(target);

      //// Dont nest checked where possible
      if (!imgGridTarget) return;
      imgGridTarget.classList.remove("active");
    });
  });
};
